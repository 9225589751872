<template>
  <AppLayout>
    <v-layout>
      <v-flex xs12>
        <v-card class="pb-3">
          <v-card-title>
            <h3>Gewerbesteuer Berechnungen</h3>
            <v-spacer />
            <v-btn :to="{ name: 'ZerlegungCreate' }" color="primary" dark
              >Neue Berechnung
            </v-btn>
            <v-menu bottom left>
              <v-btn :dark="true" class="style-switcher" slot="activator">
                {{
                  archiveFilter.find(
                    ({ archived }) => archived === isArchivedList
                  ).name
                }}
                <v-icon>keyboard_arrow_down</v-icon>
              </v-btn>
              <v-list>
                <v-list-tile
                  v-for="{ name, archived } in archiveFilter"
                  :key="archived"
                  @click="isArchivedList = archived"
                >
                  <v-list-tile-title>{{ name }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :pagination.sync="pagination"
            :total-items="total"
          >
            <template slot="items" slot-scope="props">
              <tr @click="openEntry(props.item.id)" style="cursor: pointer">
                <td>{{ props.item.year }}</td>
                <td>{{ props.item.company.name }}</td>
                <td>{{ props.item.comment }}</td>
                <td>
                  {{ props.item.calculation_type | translateTradeTaxCalcType }}
                </td>
                <td>{{ getNameById(props.item.user_id) }}</td>
                <td>
                  {{ props.item.created_at | formatDate }}
                </td>
              </tr>
            </template>

            <template slot="pageText" slot-scope="props">
              Einträge {{ props.pageStart }} bis {{ props.pageStop }} von
              insgesamt
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </AppLayout>
</template>

<script>
import tradeTaxApi from '@/api/tradeTaxApi'
import authApi from '@/api/authApi'
import { translateTradeTaxCalcType } from '@/utils/filters'
import formatDate from 'date-fns/format'
import AppLayout from '@/components/AppLayout'
import * as Sentry from '@sentry/vue'

export default {
  name: 'TaxEntries',
  components: { AppLayout },
  data() {
    return {
      items: [],
      users: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 15,
      },
      total: null,
      loading: true,
      headers: [
        { text: 'Jahr', sortable: false, value: 'year' },
        { text: 'Firma', sortable: false, value: 'company.name' },
        { text: 'Kommentar', sortable: false, value: 'comment' },
        { text: 'Berechnungsmethode', sortable: false, value: 'type' },
        { text: 'Benutzer', sortable: false, value: 'user' },
        {
          text: 'Erstellt am ...',
          sortable: false,
          value: 'created_at',
        },
      ],
      archiveFilter: [
        { name: 'Aktive', archived: false },
        { name: 'Archiviert', archived: true },
      ],
      isArchivedList: false,
    }
  },
  methods: {
    openEntry(id) {
      this.$router.push({ name: 'ZerlegungView', params: { id } })
    },
    async getEntries(page) {
      try {
        this.loading = true
        this.users = await authApi.getAll()

        const data = await tradeTaxApi.getAll(page, this.isArchivedList)

        this.total = data.total
        this.items = data.data
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        this.loading = false
      }
    },
    getNameById(id) {
      return this.users.find((user) => user.id === id)?.username
    },
  },

  mounted() {
    this.getEntries(1)
  },
  watch: {
    'pagination.page'(value) {
      this.getEntries(value)
    },
    isArchivedList() {
      this.getEntries(this.pagination.page)
    },
  },
  filters: {
    translateTradeTaxCalcType,
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY (HH:mm [Uhr])')
    },
  },
}
</script>

<style scoped></style>
