var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',[_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"pb-3"},[_c('v-card-title',[_c('h3',[_vm._v("Gewerbesteuer Berechnungen")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'ZerlegungCreate' },"color":"primary","dark":""}},[_vm._v("Neue Berechnung ")]),_c('v-menu',{attrs:{"bottom":"","left":""}},[_c('v-btn',{staticClass:"style-switcher",attrs:{"slot":"activator","dark":true},slot:"activator"},[_vm._v(" "+_vm._s(_vm.archiveFilter.find( function (ref) {
	var archived = ref.archived;

	return archived === _vm.isArchivedList;
} ).name)+" "),_c('v-icon',[_vm._v("keyboard_arrow_down")])],1),_c('v-list',_vm._l((_vm.archiveFilter),function(ref){
var name = ref.name;
var archived = ref.archived;
return _c('v-list-tile',{key:archived,on:{"click":function($event){_vm.isArchivedList = archived}}},[_c('v-list-tile-title',[_vm._v(_vm._s(name))])],1)}),1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"pagination":_vm.pagination,"total-items":_vm.total},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openEntry(props.item.id)}}},[_c('td',[_vm._v(_vm._s(props.item.year))]),_c('td',[_vm._v(_vm._s(props.item.company.name))]),_c('td',[_vm._v(_vm._s(props.item.comment))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("translateTradeTaxCalcType")(props.item.calculation_type))+" ")]),_c('td',[_vm._v(_vm._s(_vm.getNameById(props.item.user_id)))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.created_at))+" ")])])]}},{key:"pageText",fn:function(props){return [_vm._v(" Einträge "+_vm._s(props.pageStart)+" bis "+_vm._s(props.pageStop)+" von insgesamt "+_vm._s(props.itemsLength)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }